<template>
	<div>
		<div class="flex items-center">
			<SelectionMenuField
				v-model:editing="isEditingAgent"
				:selected="taskDefinitionAgent.agent"
				selectable
				editable
				creatable
				class="flex-grow"
				:select-icon="AgentIcon"
				select-class="bg-emerald-900 text-cyan-400"
				label-class="text-slate-300"
				:options="dxAgent.pagedItems.value?.data || []"
				:loading="isUpdatingAgent"
				@update:selected="agent => onUpdateAgent({ agent_id: agent.id })"
			/>
			<div class="flex items-center">
				<ActionButton
					type="copy"
					color="sky"
					class="mr-2"
					:saving="copyAgentAction.isApplying"
					@click="copyAgentAction.trigger(taskDefinition, {id: taskDefinitionAgent.id})"
				/>
				<ActionButton
					type="trash"
					color="red"
					:saving="removeAgentAction.isApplying"
					@click="removeAgentAction.trigger(taskDefinition, {id: taskDefinitionAgent.id})"
				/>
			</div>
		</div>

		<div class="mt-4 flex items-center space-x-4">
			<div>
				<QCheckbox
					:model-value="taskDefinitionAgent.include_text"
					label="Include Text?"
					class="text-slate-500"
					@update:model-value="include_text => onUpdateAgent({ include_text })"
				/>
			</div>
			<div>
				<QCheckbox
					:model-value="taskDefinitionAgent.include_files"
					label="Include Files?"
					class="text-slate-500"
					@update:model-value="include_files => onUpdateAgent({ include_files })"
				/>
			</div>
			<div>
				<QCheckbox
					:model-value="taskDefinitionAgent.include_data"
					label="Include Data?"
					class="text-slate-500"
					@update:model-value="include_data => onUpdateAgent({ include_data })"
				/>
			</div>
		</div>

		<Transition>
			<div v-if="taskDefinitionAgent.include_data">
				<div
					v-for="(inputSchemaAssociation, index) in taskDefinitionAgent.inputSchemaAssociations"
					:key="inputSchemaAssociation.id"
					class="mt-4 flex items-start flex-nowrap"
				>
					<ActionButton
						type="trash"
						color="white"
						:action="deleteSchemaAssociationAction"
						:target="inputSchemaAssociation"
						class="mr-2"
					/>

					<SchemaEditorToolbox
						can-select
						can-select-fragment
						previewable
						button-color="bg-sky-900 text-sky-200"
						:model-value="inputSchemaAssociation.schema"
						:fragment="inputSchemaAssociation.fragment"
						:loading="inputSchemaAssociation.isSaving"
						@update:model-value="schema => updateSchemaAssociationAction.trigger(inputSchemaAssociation, { schema_definition_id: schema?.id })"
						@update:fragment="(fragment) => updateSchemaAssociationAction.trigger(inputSchemaAssociation, { schema_fragment_id: fragment?.id || null })"
					>
						<template #header-start>
							<div class="bg-sky-900 text-sky-200 rounded w-20 text-center py-1.5 text-sm mr-4">
								Input {{ index + 1 }}
							</div>
						</template>
					</SchemaEditorToolbox>
				</div>
				<div class="mt-4">
					<ActionButton
						type="create"
						color="sky"
						label="Input Schema"
						size="sm"
						class="px-4"
						:action="createSchemaAssociationAction"
						:input="{task_definition_agent_id: taskDefinitionAgent.id, category: 'input'}"
					/>
				</div>

				<div class="mt-4">
					<SchemaEditorToolbox
						can-select
						can-select-fragment
						previewable
						clearable
						button-color="bg-green-900 text-green-200"
						:model-value="taskDefinitionAgent.outputSchemaAssociation?.schema"
						:fragment="taskDefinitionAgent.outputSchemaAssociation?.fragment"
						:loading="isSavingOutputSchema || taskDefinitionAgent.outputSchemaAssociation?.isSaving"
						@update:model-value="onSelectOutputSchema"
						@update:fragment="(fragment) => updateSchemaAssociationAction.trigger(taskDefinitionAgent.outputSchemaAssociation, { schema_fragment_id: fragment?.id || null })"
					>
						<template #header-start>
							<div class="bg-green-900 text-green-200 rounded w-20 text-center py-1.5 text-sm mr-4">Output</div>
						</template>
					</SchemaEditorToolbox>
				</div>
			</div>
		</Transition>
	</div>
</template>
<script setup lang="ts">
import { dxAgent } from "@/components/Modules/Agents";
import SchemaEditorToolbox from "@/components/Modules/SchemaEditor/SchemaEditorToolbox";
import { dxSchemaAssociation } from "@/components/Modules/Schemas/SchemaAssociations";
import { dxTaskDefinition } from "@/components/Modules/TaskDefinitions";
import { ActionButton } from "@/components/Shared";
import { TaskDefinition, TaskDefinitionAgent } from "@/types";
import { FaSolidRobot as AgentIcon } from "danx-icon";
import { SelectionMenuField } from "quasar-ui-danx";
import { ref } from "vue";

defineEmits(["update", "remove"]);
const props = defineProps<{
	taskDefinition: TaskDefinition;
	taskDefinitionAgent: TaskDefinitionAgent;
}>();

dxAgent.initialize();

const isEditingAgent = ref(false);
const copyAgentAction = dxTaskDefinition.getAction("copy-agent");
const updateAgentAction = dxTaskDefinition.getAction("update-agent");
const removeAgentAction = dxTaskDefinition.getAction("remove-agent");
const createSchemaAssociationAction = dxSchemaAssociation.getAction("quick-create", { onFinish: () => dxTaskDefinition.routes.detailsAndStore(props.taskDefinition) });
const updateSchemaAssociationAction = dxSchemaAssociation.getAction("update");
const deleteSchemaAssociationAction = dxSchemaAssociation.getAction("delete", { onFinish: () => dxTaskDefinition.routes.detailsAndStore(props.taskDefinition) });

const isUpdatingAgent = ref(false);
const isSavingOutputSchema = ref(false);

async function onUpdateAgent(data) {
	if (data.agent_id) {
		isUpdatingAgent.value = true;
	}
	await updateAgentAction.trigger(props.taskDefinition, { id: props.taskDefinitionAgent.id, ...data });
	isUpdatingAgent.value = false;
}

async function onSelectOutputSchema(schema) {
	isSavingOutputSchema.value = true;
	if (props.taskDefinitionAgent.outputSchemaAssociation) {
		if (!schema) {
			await deleteSchemaAssociationAction.trigger(props.taskDefinitionAgent.outputSchemaAssociation);
		} else {
			await updateSchemaAssociationAction.trigger(props.taskDefinitionAgent.outputSchemaAssociation, { schema_definition_id: schema?.id });
		}
	} else {
		await createSchemaAssociationAction.trigger(null, {
			task_definition_agent_id: props.taskDefinitionAgent.id,
			schema_definition_id: schema?.id,
			category: "output"
		});
	}
	isSavingOutputSchema.value = false;
}
</script>
